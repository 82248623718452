.dashboard-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(249, 249, 249, 0.9); /* Light background for the entire page */
  color: #333; /* Dark text color for readability */
  background-image: url('C:\Users\supra\Downloads\Insolvency_Dashboard\Website\frontend\my-app\public\bgd.png'); /* Path to your background image */
  background-size: cover;
  background-position: center;
}

.iframe-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 0 20px 20px; /* Add padding to the container */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;
}

.iframe {
  width: 100%;
  flex: 1; /* Take up remaining space */
  border: none;
  border-radius: 8px;
  transform-origin: top left;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0; /* Add padding to controls */
}

.back-button {
  padding: 10px 20px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #004B99;
}

.zoom-controls {
  text-align: center;
}

/* Placeholder styles */
.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  font-size: 1.2em;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .iframe {
    height: calc(100vh - 150px); /* Adjust height for mobile view */
  }

  .controls {
    flex-direction: column;
    align-items: stretch;
  }

  .back-button {
    width: 100%; /* Full width button on mobile */
    margin-bottom: 10px;
  }
}
