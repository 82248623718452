/* ResearchPage.css */

.page-container {
  text-align: center;
  padding: 20px;
  background: white;
  color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.page-container h1, .page-container h2 {
  color: #333;
  margin: 20px 0;
}

.page-container p, .page-container a {
  color: #0066CC;
  font-size: 1.1em;
  margin: 10px 0;
}

.page-container a {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2em;
  transition: color 0.3s ease;
}

.page-container a:hover {
  text-decoration: underline;
  color: #004999;
}

.tab-menu {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-button.active {
  background-color: #0066CC; /* Blue background for active tab */
  color: white; /* White text for active tab */
}

.tab-button:not(.active) {
  background-color: white; /* White background for inactive tab */
  color: #0066CC; /* Blue text for inactive tab */
}

.tab-content {
  max-width: 800px;
  margin: 20px auto;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tab-content h2 {
  margin-bottom: 10px;
}

.about-text {
  line-height: 1.6;
}

.findings-list {
  list-style-type: disc;
  margin: 20px 0;
}

.chart-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.chart-image {
  max-width: 600px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.chart-image:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.overview-content {
  background: white;
}

.developments-content {
  background: #cfe4f6; /* Light blueish background for developments */
}

.findings-content {
  background: #ffffff; /* Blueish background for findings */
}

.data-content {
  background: #b4d7f6; /* Light blueish background for data */
}
.tab-content h2 {
  margin-bottom: 10px;
}

.about-text {
  line-height: 1.6;
}

.findings-list {
  list-style-type: disc;
  margin: 20px 0;
}

.chart-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.chart-image {
  max-width: 600px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

