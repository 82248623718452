
  .modal-content {
    background: rgb(213, 242, 250);
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: auto;
  }
  
  .research-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
  }
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5em;
    cursor: pointer;
  }
  

  
  .research-tile {
    background: #f9f9f9;
    border-radius: 8px;
    padding: 20px;
    width: 200px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .research-tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .research-tile img {
    width: 50%;
    border-radius: 8px;
  }
  
  .research-tile h3 {
    margin-top: 10px;
    font-size: 1.2em;
    color: #333;
  }
  