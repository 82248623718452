.our-team-container {
  text-align: center;
  padding: 20px;
  background-color: #e6f2ff; /* Light blue background */
  color: #333;
}

.our-team-container h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.our-team-container p {
  font-size: 1.2em;
  margin-bottom: 40px;
}

.team-section {
  display: flex;
  justify-content: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-gap: 20px; /* space between items */
}

.team-member {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.team-member:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.team-member h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.team-member p {
  font-size: 1em;
  color: #666;
}

/* Media Queries for Mobile Compatibility */
@media (max-width: 768px) {
  .our-team-container h1 {
    font-size: 2em;
  }

  .our-team-container p {
    font-size: 1.1em;
  }

  .team-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets */
    grid-gap: 15px; /* slightly less space between items */
  }

  .team-member h3 {
    font-size: 1.3em;
  }

  .team-member p {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .our-team-container h1 {
    font-size: 1.8em;
  }

  .our-team-container p {
    font-size: 1em;
  }

  .team-grid {
    grid-template-columns: 1fr; /* 1 column on mobile devices */
    grid-gap: 10px; /* smaller space between items */
  }

  .team-member {
    padding: 15px;
  }

  .team-member h3 {
    font-size: 1.2em;
  }

  .team-member p {
    font-size: 0.8em;
  }
}
