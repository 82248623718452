html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Navigation Bar */
.nav-bar {
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0; /* Remove padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  margin: 0; /* Remove margin */
}

.logo-link {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Reduce margin */
}

.logo {
  height: 50px;
  margin: 0; /* Remove margin */
}

.nav-list {
  list-style-type: none;
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
  display: flex;
  align-items: center;
}

.nav-list li {
  float: left;
  position: relative;
  margin-right: 10px; /* Reduce space between tabs */
}

.nav-list li:last-child {
  margin-right: 0; /* No margin for the last item */
}

.nav-list li a {
  display: block;
  color: black; /* Black text color */
  text-align: center;
  padding: 14px 10px; /* Adjust padding */
  text-decoration: none;
  position: relative;
  transition: color 0.3s; /* Smooth transition for text color */
  font-weight: bold;
  font-size: 1em; /* Adjust font size for better readability */
  margin: 0; /* Remove margin */
}
body.hide-nav .nav-bar {
  display: none;
}

/* Navigation Bar */
.nav-list li a::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px; /* Height of the hover effect */
  background-color: #0066CC; /* Blue color */
  transition: width 0.3s ease, left 0.3s ease; /* Smooth transition */
}

.nav-list li a:hover::after {
  width: 100%;
  left: 0;
}

.nav-list li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 50%;
  background-color: #0066CC; /* Blue separator */
  transform: translateY(-50%);
}

/* Mobile Navigation */
@media (max-width: 768px) {
  .nav-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-list {
    flex-direction: column;
    width: 100%;
    display: none; /* Initially hidden */
  }

  .nav-list.show {
    display: flex; /* Show when toggled */
  }

  .nav-list li {
    width: 100%;
  }

  .nav-list li a {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .nav-list li a::after {
    display: none;
  }

  .hamburger {
    display: block;
    cursor: pointer;
    margin-left: auto;
  }

  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
  }
}

/* Page Container */
.page-container {
  text-align: center;
  padding: 20px;
  background: white;
  color: #333;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 60px;
  background-image: url('C:/Users/supra/Downloads/Insolvency_Dashboard/Website/frontend/my-app/public/bgd.png'); /* Ensure the image path is correct */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.page-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: -1;
}

.page-container h1, .page-container h2 {
  color: #333;
}

.page-container p, .page-container a {
  color: #0066CC;
}

.page-container a {
  text-decoration: none;
  font-weight: bold;
}

.page-container a:hover {
  text-decoration: underline;
}

/* Sections */
.section {
  flex: 1;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f8f9fa;
  color: #333;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

/* Responsive Section */
@media (max-width: 768px) {
  .section {
    padding: 15px;
    margin: 5px;
  }
}

.section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.section p {
  font-size: 1em;
  margin-bottom: 10px;
}

.section a {
  color: #0066CC;
  text-decoration: none;
  font-weight: bold;
}

.section a:hover {
  text-decoration: underline;
}

/* Iframe Container */
.iframe-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 0 20px 20px; /* Add padding to the container */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 100%;
}

.iframe {
  width: 100%;
  flex: 1; /* Take up remaining space */
  border: none;
  border-radius: 8px;
  transform-origin: top left;
}

/* Responsive Iframe Container */
@media (max-width: 768px) {
  .iframe-container {
    padding: 15px;
  }
}

/* Dashboard Container */
.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-header h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.dashboard-header p {
  font-size: 1.2em;
  max-width: 800px;
}

.dashboard-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  box-sizing: border-box;
}

/* Responsive Dashboard Content */
@media (max-width: 768px) {
  .dashboard-content {
    flex-direction: column;
    align-items: center;
  }
}

.sidebar {
  flex: 1;
  width: 30%; /* Adjust width to 30% */
  background: #ffffff;
  padding: 20px;
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Responsive Sidebar */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.sidebar h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: #0066cc;
  text-decoration: none;
  font-weight: bold;
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

.iframe-container {
  flex: 2;
  width: 100%; /* Ensure full width */
  background: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 0;
  display: flex;
  flex-direction: column;
}

.iframe {
  width: 100%;
  flex: 1; /* Take up remaining space */
  border: none;
  border-radius: 8px;
  transform-origin: top left;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  font-size: 1.2em;
  color: #666;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0; /* Add padding to controls */
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9); /* Add background to make controls more visible */
}

.back-button {
  padding: 10px 20px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #004B99;
}

.zoom-controls {
  text-align: center;
  margin-right: 20px;
}

/* Research Modal */
.research-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.research-card {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.research-image {
  width: 100%;
  border-radius: 8px;
}

.research-card h2 {
  margin-top: 10px;
  color: #0066CC;
}

.research-card p {
  color: #333;
}

.research-card h3 {
  color: #0066CC;
}

.research-card ul {
  list-style-type: disc;
  padding-left: 20px;
}

.research-card a {
  color: #0066CC;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
}

.research-card a:hover {
  text-decoration: underline;
}

/* Slide down animation */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
