/* Footer.css */
.footer {
  background: #333; /* Dark background */
  color: #fff; /* Light text color */
  padding: 20px 0; /* Padding for spacing */
  text-align: center; /* Centered text */
  width: 100%; /* Full width */
  position: relative; /* Relative positioning */
  bottom: 0; /* Stick to bottom */
  left: 0; /* Align left */
  border-top: 1px solid #e7e7e7; /* Light border for separation */
}

.footer-content {
  display: flex; /* Flexbox layout */
  justify-content: space-around; /* Evenly distribute space around items */
  flex-wrap: wrap; /* Wrap items to the next line if necessary */
  max-width: 1200px; /* Maximum width */
  margin: 0 auto; /* Center content */
}

.footer-section {
  flex: 1; /* Flexible width */
  margin: 10px; /* Margin for spacing */
  min-width: 200px; /* Minimum width */
}

.footer-section h4 {
  margin-bottom: 10px; /* Space below heading */
  font-size: 1.2em; /* Font size */
  color: #0066CC; /* Blue accent color for headings */
}

.footer-section p, .footer-section a {
  color: #fff; /* Light text color */
  font-size: 1em; /* Font size */
  text-decoration: none; /* Remove underline */
}

.footer-section a:hover {
  text-decoration: underline; /* Underline on hover */
  color: #004B99; /* Darker blue on hover */
}

.social-icons a {
  margin: 0 10px; /* Margin for spacing */
  display: inline-block; /* Inline block display */
  padding: 5px; /* Padding for spacing */
  color: #fff; /* Light text color */
  font-size: 1em; /* Font size */
  text-decoration: none; /* Remove underline */
}

.social-icons a:hover {
  color: #004B99; /* Darker blue on hover */
}

.partner-logo {
  width: 50px; /* Width */
  height: auto; /* Maintain aspect ratio */
  margin: 5px; /* Margin for spacing */
}


