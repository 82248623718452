.data-page {
  padding: 20px;
  background-color: #f9f9f9;
  font-family: 'Lora', serif;
  max-width: auto;
  margin: 0 auto;
  text-align: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

}

.data-page h1, .data-page h2 {
  text-align: center;
}

.tab-menu {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.tab-menu button {
  padding: 10px 20px;
  background-color: #e6f2ff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

.tab-menu button.active, .tab-menu button:hover {
  background-color: #b3d1ff;
}

.tab-content {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  
}

.overview-content {
  background: white;
  text-align: center;
}

.data-sources-content {
  background: #cbe4fc; /* Light blue background */
}

.workflow-content {
  background: white;
  text-align: center;
}

.available-data-content {
  background: #e6f2ff; /* Light blue background */
  text-align: center;
}

.data-boxes {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.data-box {
  background-color: rgb(153, 188, 244);
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  flex: 1;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.data-box h3 {
  margin-top: 0;
  font-size: 1.2em;
}

.data-section ul {
  list-style-type: disc;
  margin-left: 20px;
}

.data-section p {
  font-size: 1em;
  margin-bottom: 10px;
}

.workflow-image {
  display: block;
  margin: 20px auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.data-table tr:nth-child(even) {
  background-color: #f9f9f9;
}
