html, body, #root {
  margin: 0;
  padding: 0;
}

.nav-bar {
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px; /* Adjusted padding */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  border-bottom: 2px solid #0066CC; /* Blue line beneath the navigation bar */
}

.logo-link {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px; /* Adjust the height as needed */
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust gap between links */
}

.nav-list li {
  position: relative;
}

.nav-list li a {
  display: block;
  color: black; /* Black text color */
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1em; /* Adjust font size */
}

.nav-list li a::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px; /* Height of the hover effect */
  background-color: #0066CC; /* Blue color */
  transition: width 0.3s ease, left 0.3s ease;
}

.nav-list li a:hover::after {
  width: 100%;
  left: 0;
}

.nav-list li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px; /* Adjust spacing */
  width: 1px;
  height: 24px; /* Adjust height */
  background-color: #0066CC; /* Blue separator */
  transform: translateY(-50%);
}

.nav-bar .search-icon {
  cursor: pointer;
  font-size: 1.5em;
  margin-left: 20px;
}


/* Hamburger Menu */
.hamburger {
  display: none; /* Hide by default */
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s; /* Add transition for hover effect */
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px 0;
  transition: background-color 0.3s; /* Add transition for hover effect */
}

.hamburger:hover {
  transform: rotate(90deg); /* Rotate the hamburger menu on hover */
}

.hamburger:hover div {
  background-color: #0066CC; /* Change to blue on hover */
}

/* Mobile Navigation */
@media (max-width: 768px) {
  .nav-list {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .nav-list.active {
    display: flex;
  }

  .nav-list li {
    width: 100%;
  }

  .nav-list li a {
    text-align: left;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }

  .nav-list li a::after {
    display: none;
  }

  .hamburger {
    display: flex; /* Show hamburger menu on mobile */
  }

  .hamburger div {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 4px 0;
  }
}

/* Page Container */
.page-container {
  text-align: center;
  padding: 20px;
  background-color: white; /* White background */
  color: black; /* Black text color */
  min-height: 100vh; /* Make sure the background covers the full height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px; /* Add padding to avoid content being hidden behind the footer */
}

/* App.css */
.app-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(to bottom right, #e6f7ff, #ccf2ff); /* Light blue gradient */
  color: #333; /* Dark text color */
}

.content {
  flex: 1;
}

.page-container h1, .page-container h2 {
  color: black; /* Black text color */
}

.page-container p, .page-container a {
  color: black; /* Black text color */
}

.page-container a {
  text-decoration: none;
  font-weight: bold;
  color: #0066CC; /* Blue accent color */
}

.page-container a:hover {
  text-decoration: underline;
  color: #004B99; /* Darker blue on hover */
}

.section {
  flex: 1;
  margin: 10px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #f9f9f9; /* Light grey background for sections */
  color: black; /* Black text color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: black; /* Black text color */
}

.section p {
  font-size: 1em;
  margin-bottom: 10px;
  color: black; /* Black text color */
}

.section a {
  color: #0066CC; /* Blue accent color */
  text-decoration: none;
  font-weight: bold;
}

.section a:hover {
  text-decoration: underline;
  color: #004B99; /* Darker blue on hover */
}

/* Search Component */
.search-bar {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.search-bar input {
  width: 200px;
  padding: 5px;
  font-size: 1em;
}

.search-bar button {
  padding: 5px 10px;
  font-size: 1em;
  background-color: #0066cc;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 10px;
}

.search-bar button:hover {
  background-color: #004b99;
}

/* Indicators Overlay */
.indicators-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  animation: slideDown 0.3s ease-out; /* Slide down animation */
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.indicators-popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80%;
  max-width: 800px;
}

.indicators-popup h2 {
  margin-bottom: 20px;
}

.indicators-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.indicator-card {
  background: #f0f0f0;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  transition: background 0.3s;
}

.indicator-card:hover {
  background: #1b7ddf; /* Lighter blue */
  color: white; /* Text color change on hover */
}

.indicator-card p {
  margin: 10px 0 5px;
  font-weight: bold;
}

.indicator-card small {
  display: block;
  color: #666;
}

.indicators-popup button {
  padding: 10px 20px;
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.indicators-popup button:hover {
  background-color: #004b99;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 70px; /* Adjusted to start just below the nav bar */
  left: 50;
  width: 100%;
  height: calc(100% - 70px); /* Adjusted height to account for nav bar */
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  animation: fadeIn 0.3s ease;
}

.modal-content {
  background: white;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50;
  border-radius: 0;
  box-shadow: black;
  overflow-y: auto;
  padding: 20px;
  animation: slideDown 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

/* Hide nav styles */
.hide-nav .nav-bar {
  display: none;
}
