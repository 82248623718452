@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400&display=swap');

body, html, .home-container {
  font-family: 'Lora', serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 150%;
}

.home-container {
  text-align: center;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.top-section {
  display: flex;
  width: 100%;
  height: calc(100vh - 60px);
  position: relative;
  gap: 1px; /* Adds a little white space between the images */
}

.left-pane {
  flex: 2;
  position: relative;
}

.right-pane {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3px; /* Adds a little white space between the images */
}

.right-top, .right-bottom {
  height: 50%;
  position: relative;
  flex: 1;
}

.left-image, .right-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay-text, .overlay-text-small {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.overlay-text:hover, .overlay-text-small:hover {
  background-color: rgba(0, 0, 139, 0.5); /* Dark blue tint */
}

.overlay-text h2, .overlay-text-small h3 {
  margin: 0;
  font-weight: 400;
  font-style: normal;
}

.overlay-text p, .overlay-text-small p {
  margin: 10px 0 0;
  font-weight: 400;
  font-style: normal;
}

.overlay-text button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.overlay-text button:hover {
  background-color: #0056b3;
}

.overlay-text-small {
  padding: 10px;
}

.overlay-text-small h3 {
  font-size: 1.2em;
}

.overlay-text-small p {
  font-size: 0.9em;
  text-decoration: underline;
  cursor: pointer;
}

/* Add hover effect for right-pane images */
.right-image:hover {
  filter: brightness(70%);
  transition: filter 0.3s ease-in-out;
}

.right-image {
  filter: brightness(100%);
  transition: filter 0.3s ease-in-out;
}

.what-we-do-section, .milestones-section {
  width: 100%; /* Ensure full width */
  padding: 60px 20px;
  text-align: center;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.what-we-do-section {
  background-color: #f5f8fa;
}

.what-we-do-header {
  background-color: #cce7ff;
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.mission-statement {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 40px;
}

.mission-chart {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.mission-chart img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.milestones-section {
  background-color: #e6f2ff; /* Light blue background */
}

.milestones-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.milestones-section img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .top-section {
    flex-direction: column;
    height: auto;
  }

  .left-pane, .right-top, .right-bottom {
    height: 50vh;
  }

  .overlay-text h2 {
    font-size: 2em;
  }

  .overlay-text p {
    font-size: 1em;
  }

  .overlay-text button {
    padding: 8px 16px;
  }

  .overlay-text-small h3 {
    font-size: 1.2em;
  }

  .overlay-text-small p {
    font-size: 0.9em;
  }
}
